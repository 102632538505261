import React, { useState } from "react";
import PDFIcon from "./images/pdf-icon.png";
import heroSectionPolicy from "./images/hero-section-policy-img.webp";
import ANNUAL_Return_FY_2020_21 from "./pdf/ANNUAL RETURN_FY 2020-2021.pdf";
import ANNUAL_Return_FY_2021_22 from "./pdf/ANNUAL RETURN_FY 2021-2022.pdf";
import ANNUAL_Return_FY_2022_23 from "./pdf/ANNUAL RETURN_FY 2022-2023.pdf";
import "./style.css";

let annualReturnFYs = [
  {
    name: "2020-21",
    url: ANNUAL_Return_FY_2020_21,
  },
  {
    name: "2021-22",
    url: ANNUAL_Return_FY_2021_22,
  },
  {
    name: "2022-23",
    url: ANNUAL_Return_FY_2022_23,
  },
];
const AnnualReturnContainer = ({ pdfIcon, policyName, policyUrl }) => {
  return (
    <div className="policy-message-box-container">
      <div className="policy-message-box-icon">
        <img
          src={pdfIcon}
          alt="pdf-icon"
          style={{
            height: 30,
            width: 30,
          }}
        />
      </div>
      <p style={{ marginBottom: 10 }}>
        <strong>{policyName}</strong>
      </p>
      <p>
        <a
          className="read-more"
          href={policyUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Read More
        </a>
      </p>
    </div>
  );
};
const AnnualReturn = () => {
  const [selectedItem, setSelectedItem] = useState(
    annualReturnFYs[0]?.name || null
  );
  const [animationTrigger, setAnimationTrigger] = useState(false);
  const [animationDirection, setAnimationDirection] = useState("");
  const [animationKey, setAnimationKey] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);

  const handleItemClick = (policyName, index) => {
    setSelectedItem(policyName);
    setAnimationTrigger(true);
    setAnimationKey((prevKey) => prevKey + 1);
    if (index > prevIndex) {
      setAnimationDirection("down");
    } else if (index < prevIndex) {
      setAnimationDirection("up");
    } else {
      setAnimationDirection("");
    }

    setPrevIndex(index);
  };

  const renderAnnualReturnContainers = (selectedItem) => {
    const annualReturn = annualReturnFYs.find(
      (annualReturn) => annualReturn.name === selectedItem
    );
    if (!annualReturn) return null;

    return (
      <div
        key={animationKey}
        className={`policy-attachments ${
          animationTrigger ? `animate-${animationDirection}` : ""
        }`}
      >
        <AnnualReturnContainer
          pdfIcon={PDFIcon}
          policyName={annualReturn.name}
          policyUrl={annualReturn.url}
        />
      </div>
    );
  };

  return (
    <div>
      <div className="main wrapper">
        {/* <!-- *************** HERO SECTION STARTS *************** --> */}
        <section className="hero-section">
          <img src={heroSectionPolicy} alt="" />
          <div className="hero-section-box">
            <div className="hero-section-text">
              <p>ANNUAL RETURN</p>
            </div>
          </div>
        </section>

        {/* <!-- *************** ANNUAL RETURN SECTION STARTS *************** --> */}

        <section className="policy-section">
          <div className="container policy-container">
            {/* <h1 className="main-heading">POLICIES</h1> */}
            {/* <div className="policy-items-wrapper"> */}
            <div className="policy-side-nav">
              {annualReturnFYs.map((annualReturn, index) => {
                return (
                  <>
                    {/* <h4>{annualReturn.name}</h4> */}
                    <div
                      key={index}
                      className={`policy-side-nav-item ${
                        selectedItem === annualReturn.name
                          ? "policy-selected"
                          : ""
                      }`}
                      onClick={() => handleItemClick(annualReturn.name, index)}
                    >
                      {annualReturn.name}
                    </div>
                  </>
                );
              })}
            </div>
            {selectedItem && renderAnnualReturnContainers(selectedItem)}
            {/* </div> */}
          </div>
        </section>
      </div>
    </div>
  );
};

export default AnnualReturn;
